import axios from "axios";

export default class BackendConnectionService {

    static getEndpoint() {
        if(process.env.NODE_ENV === "development") {
            return "http://localhost:8081/";
        } else {
            return "https://admin.conperbcn.com/";
        }
    }

    static buildHeaders(token) {
        return {
            "G-TOKEN": token
        };
    }

    static buildAuthHeader(token) {
        return {
            Authorization: "Bearer " + token
        }
    }

    static loginAttempt(password,captcha) {
        let endpoint = this.getEndpoint() + "v1/login";
        let headers = this.buildHeaders(captcha);
        return axios.post(endpoint, {
            password: password
        }, {
            headers: headers
        })
    }

    static getMetadata(token) {
        let endpoint = this.getEndpoint() + "v1/metadata/metadata";
        let headers = this.buildAuthHeader(token);
        return axios.get(endpoint, {
            headers: headers
        });
    }

    static getPublicMetadata() {
        let endpoint = this.getEndpoint() + "v1/metadata/publicMetadata";
        return axios.get(endpoint);
    }

    static updatePublicMetadata(message, type, token) {
        let endpoint = this.getEndpoint() + "v1/metadata/metadata";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint, {
            message: message,
            type: type
        }, {
            headers: headers
        });
    }

    static changePassword(token, current, newPass) {
        let endpoint = this.getEndpoint() + "v1/metadata/password";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint, {
            current: current,
            newPass: newPass
        }, {
            headers: headers
        });
    }

    static addPasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v1/tramite/pasaporte";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,
            {
                tag: etiqueta
            },
            {
                headers: headers
            });
    }

    static markPasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v1/tramite/pasaporte/" + etiqueta;
        let headers = this.buildAuthHeader(token);
        return axios.put(endpoint,{}, {
            headers: headers
        });
    }

    static markDNI(token, dni) {
        let endpoint = this.getEndpoint() + "v1/tramite/dni/" + dni;
        let headers = this.buildAuthHeader(token);
        return axios.put(endpoint,{}, {
            headers: headers
        });
    }

    static deletePasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v1/tramite/pasaporte/" + etiqueta;
        let headers = this.buildAuthHeader(token);
        return axios.delete(endpoint, {
            headers: headers
        });
    }

    static getPasaporte(token, etiqueta) {
        let endpoint = this.getEndpoint() + "v1/tramite/pasaporte/" + etiqueta;
        let headers = this.buildHeaders(token);
        return axios.get(endpoint, {
            headers:headers
        });
    }

    static getDNI(token, dni) {
        let endpoint = this.getEndpoint() + "v1/tramite/dni/" + dni;
        let headers = this.buildHeaders(token);
        return axios.get(endpoint, {
            headers:headers
        });
    }

    static uploadDNIs(token, data) {
        let endpoint = this.getEndpoint() + "v1/tramite/dni";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,data, {
            headers: headers
        });
    }

    static uploadCertificado(token, data) {
        let endpoint = this.getEndpoint() + "v1/tramite/certificado";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,data, {
            headers: headers
        });
    }

    static uploadPasaportesBatch(token, data) {
        let endpoint = this.getEndpoint() + "v1/tramite/pasaporte/batch";
        let headers = this.buildAuthHeader(token);
        return axios.post(endpoint,data, {
            headers: headers
        });
    }

    static getTramiteMetadata(token,tramite) {
        let endpoint = this.getEndpoint() + "v1/tramite/"+tramite+"/metadata";
        let headers = this.buildAuthHeader(token);
        return axios.get(endpoint, {
            headers: headers
        });
    }

}