import React from "react";
import en_flag from "../assets/flags/us.jpg";
import es_flag from "../assets/flags/spain.jpg";
import LocalStorageService from "../ports/localStorage/localStorageService";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

class LangSwitcher extends React.Component {
    languageMap = new Map([
        ["es", es_flag],
        ["en", en_flag]
    ]);
    setLanguage(lang) {
        i18n.changeLanguage(lang);
    }
    getCurrentLang() {
        let currentLang = (LocalStorageService.getUserLanguage()).substring(0,2);
        return (
            <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="" src={this.languageMap.get(currentLang)} alt="Header Language" height="14" />
            </button>
        );
    }
    render() {
        const noLink = "#";
        const langEnabled = false;
        if(langEnabled) {
            return(
                <div className="dropdown d-inline-block">
                    {this.getCurrentLang()}
                    <div className="dropdown-menu dropdown-menu-right">

                        <a href={noLink} onClick={() => this.setLanguage("es")} className="dropdown-item notify-item">
                            <img src={es_flag} alt="spanish" className="mr-2" height="12"/><span
                            className="align-middle">{this.props.t('components.language.spanish')}</span>
                        </a>

                        <a href={noLink} onClick={() => this.setLanguage("en")} className="dropdown-item notify-item">
                            <img src={en_flag} alt="english" className="mr-2" height="12"/><span
                            className="align-middle">{this.props.t('components.language.english')}</span>
                        </a>
                    </div>
                </div>
            );
        } else {
            return(<div />);
        }
    }
}

export default withTranslation()(LangSwitcher);