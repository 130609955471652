import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATION_EN from "../../assets/translations/en.json";
import TRANSLATION_ES from "../../assets/translations/es.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATION_EN
            },
            es: {
                translation: TRANSLATION_ES
            }
        }
    });

i18n.changeLanguage("es");