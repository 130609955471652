
export default class ResponseProcessorService {
    static processResponse(response) {
        if(response.response !== undefined) {
            return this.processNegativeResponse(response);
        }
        return this.processPositiveResponse(response);
    }

    static processTramiteMetadata(response) {
        let payload = this.processResponse(response);
        return {
            estado: payload.payload.uploadStage,
            response: payload.payload.data,
            actualizacion: payload.payload.date
        };
    }

    static processPositiveResponse(response) {
        let payload = response.data;
        return {
            success: true,
            payload: payload
        };
    }

    static processNegativeResponse(response) {
        return {
            success: false,
            errorCode: response.response.status,
            errorMsg: response.response.data.message
        }
    }
}
