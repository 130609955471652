import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import DNI from "../components/tramites/dni";
import Pasaporte from "../components/tramites/pasaporte";
import LocalStorageService from "../ports/localStorage/localStorageService";
import {withTranslation} from "react-i18next";

class Home extends React.Component {

    constructor(props) {
        super(props);
        if(LocalStorageService.getTramite() == null) {
            LocalStorageService.changeTramite("dni");
        }
        this.state = {
            message: null,
            type: null
        }
    }

    render() {
        return(
                <div id="layout-wrapper">
                    <Navbar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <DNI />
                                <Pasaporte />
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
        </div>
        );
    }
}
export default withTranslation()(Home);
