import React from "react";
import LocalStorageService from "../../ports/localStorage/localStorageService";
import Dashboard from "./Dashboard";
import Login from "./Login";

export default class Admin extends React.Component {
    render() {
        if(LocalStorageService.getUserToken() != null) {
            return <Dashboard />
        } else {
            return <Login />
        }
    }
}