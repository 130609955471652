import React from "react";

export default class FileHelper {
    static generateOpts(columns) {
        let cols = columns;
        let elems = [];
        let i = 0;
        elems.push(<option key="" value="">Seleccione...</option>)
        cols.forEach(row => {
            elems.push(<option key={i} value={i}>{row}</option>);
            i = i + 1;
        });
        return elems;
    }

    static csvJSON(csv){
        let lines=csv.split("\n");
        let result = [];
        let headers=lines[0].split(",");
        for(let i=1;i<lines.length;i++){
            let obj = {};
            let currentline=lines[i].split(",");
            for(let j=0;j<headers.length;j++){
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        return JSON.stringify(result);
    }
}
