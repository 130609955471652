import React from "react";
import {withTranslation} from "react-i18next";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import {toast} from "react-toastify";
import LocalStorageService from "../../ports/localStorage/localStorageService";
import Toastify from "../alerts/Toastify";
import ReCaptchaV2 from "react-google-recaptcha";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pass: "",
            captcha: null
        }
        this.attemptLogin = this.attemptLogin.bind(this);
        this.onCaptcha = this.onCaptcha.bind(this);
    }
    updatePass(prop) {
        this.setState({
           pass: prop.target.value
        });
    }
    onCaptcha(e) {
        this.setState({
            captcha: e
        });
    }
    async attemptLogin() {
        let {captcha, pass} = this.state;
        let ready = true;
        if (pass == null || pass.trim() === "") {
            ready = false;
            let error = this.props.t("components.toast.error.emptyfields");
            Toastify.error(toast, error);
        }
        if (captcha == null) {
            ready = false;
            Toastify.error(toast, "Por seguridad, debe marcar No soy un robot para continuar.");
        }

        if (ready) {
            await BackendConnectionService.loginAttempt(pass, captcha).then((e) => {
                LocalStorageService.setUserInformation(e.data);
                window.location.reload();
            }).catch((err) => {
                let resp = "";
                if(err.response) {
                    resp = err.response.data.message;
                } else if(err.request) {
                    resp = "Parece que hay problemas de conexión con el servidor. Vuelva a intentarlo más tarde.";
                } else {
                    resp = "Se ha producido un error en el sistema. Vuelva a intentarlo más tarde.";
                }
                Toastify.error(toast,resp);

                // eslint-disable-next-line no-undef
                grecaptcha.reset();
            });
        }

    }
    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-lg-5">
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="p-2">
                                <h5 className="mb-5 text-center">{this.props.t("admin.pages.login.logintocontinue")}</h5>
                                <form className="form-horizontal">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4">
                                                <label htmlFor="acceso">{this.props.t("admin.pages.login.password")}</label>
                                                <input type="password" className="form-control" id="acceso" onChange={(e) => this.updatePass(e)}
                                                       placeholder="*****" />
                                            </div>
                                            <div className="mt-4">
                                                <ReCaptchaV2 sitekey="6LcInNQeAAAAALgojcIsIrmzLWLCnUZzwaqCEif2" onChange={this.onCaptcha} />
                                            </div>
                                            <div className="mt-4">
                                                <button
                                                    className="btn btn-success btn-block waves-effect waves-light"
                                                    type="button" onClick={() => this.attemptLogin()}>{this.props.t("admin.pages.login.login")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LoginForm);