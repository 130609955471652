import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/admin/Admin";
import LocalStorageService from "./ports/localStorage/localStorageService";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NotFound from "./pages/404";
import Configuration from "./pages/admin/Configuration";
import Login from "./pages/admin/Login";
import AdminDNI from "./pages/admin/AdminDNI";
import AdminPass from "./pages/admin/AdminPass";
import Mantenimiento from "./pages/Mantenimiento";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        if(LocalStorageService.isFirstSetup()) {
            LocalStorageService.doFirstSetup();
        }
        this.state = {
            mantenimiento: false
        }
    }

  render() {

      if(this.state != null) {
          let {mantenimiento} = this.state;
          if(mantenimiento) {
              return <Mantenimiento />
          } else {
              return (
                  <div className="App">
                      <ToastContainer
                          position="top-right"
                          autoClose={3000}
                          hideProgressBar={true}
                          newestOnTop={false}
                          closeOnClick={true}
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                      />
                      <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="admin" element={<Admin />} />
                          <Route path="admin/config" element={<Configuration />} />
                          <Route path="admin/dni" element={<AdminDNI />} />
                          <Route path="admin/pasaporte" element={<AdminPass />} />
                          
                          <Route path="admin/inactive" element={<Login error={"Se ha cerrado sesión por inactividad."}/>}/>
                          <Route path="*" element={<NotFound />}/>
                      </Routes>
                  </div>
              );
          }
      } else {
          return <div>Cargando...</div>
      }
  }
}
