import {withTranslation} from "react-i18next";
import React from "react";
import Navbar from "../../components/navbar";
import AdminMenu from "../../components/admin/adminMenu";
import Footer from "../../components/footer";
import RecogerDNI from "../../components/admin/tramites/RecogerDNI";
import SubirDNI from "../../components/admin/tramites/SubirDNI";
import LocalStorageService from "../../ports/localStorage/localStorageService";
import BackendConnectionService from "../../ports/backend/backendConnectionService";
import ResponseProcessorService from "../../ports/backend/ResponseProcessorService";
import Toastify from "../../components/alerts/Toastify";
import {toast} from "react-toastify";
import Warning from "../../components/alerts/Warning";
import SubidaResponse from "../../components/admin/subidaResponse";

class AdminDNI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            estado: null,
            response: null,
            actualizacion: null
        }
    }
    componentDidMount() {
        let token = LocalStorageService.getUserToken();
        BackendConnectionService.getMetadata(token)
            .then((res) => {
                ResponseProcessorService.processResponse(res);
            })
            .catch((error) => {
                this.processError(error);
            });

        BackendConnectionService.getTramiteMetadata(token, "dni")
            .then((response) => {
                let info = ResponseProcessorService.processTramiteMetadata(response);
                this.setState(info);
            }).catch((err) => {
            this.processError(err);
        })
    }

    processError(error) {
        let payload = ResponseProcessorService.processResponse(error);
        if(payload.errorCode === 401) {
            LocalStorageService.logout();
            window.location.assign("/admin/inactive");
        } else {
            Toastify.error(toast, payload.errorMsg);
        }
    }

    render() {
        let instrucciones = "Para subir un archivo de DNIs asegúrese de que el archivo solo contiene páginas con datos " +
            "Hay que eliminar las páginas de Observados, etc. Que no contienen datos a ser procesados. También verifique que la primera " +
            "fila es la que contiene los títulos de la columna correspondiente. (Por ejemplo: Primer apellido, Nombres, etc).";
        let {response, actualizacion, estado} = this.state;
        let formulario = <SubirDNI />;
        if(estado === "PROCESANDO") {
            formulario = (<div className="alert alert-primary" role="alert">
                Se está procesando un fichero actualmente. El formulario volverá a estar disponible cuando termine el proceso.
            </div>);
        }

        return(
            <div id="layout-wrapper">
                <Navbar />
                <AdminMenu />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Habilitar DNIs</h4>
                                            <div className="row">
                                                {formulario}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Marcar DNI como recogido</h4>
                                            <div className="row">
                                                <RecogerDNI />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">Estado del último archivo subido</h4>
                                            <SubidaResponse response={response} actualizacion={actualizacion} clase={estado} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-md-12">
                                                <h4 className="header-title mb-4">Instrucciones para subir archivo con DNIs</h4>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Warning message={instrucciones}  />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <ul className="nav nav-tabs nav-justified nav-tabs-custom"
                                                            role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" data-toggle="tab"
                                                                   href="#custom-home" role="tab">
                                                                    <i className="dripicons-browser-upload mr-1 align-middle"/>
                                                                    <span
                                                                        className="d-none d-md-inline-block">Paso 1: Subir archivo</span>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" data-toggle="tab"
                                                                   href="#custom-profile" role="tab">
                                                                    <i className="dripicons-document mr-1 align-middle"/>
                                                                    <span
                                                                        className="d-none d-md-inline-block">Paso 2: Seleccionar columnas</span>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" data-toggle="tab"
                                                                   href="#custom-messages" role="tab">
                                                                    <i className="dripicons-checklist mr-1 align-middle" />
                                                                    <span
                                                                        className="d-none d-md-inline-block">Paso 3: Previsualizar resultados</span>
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" data-toggle="tab"
                                                                   href="#custom-settings" role="tab">
                                                                    <i className="dripicons-checkmark mr-1 align-middle" />
                                                                    <span
                                                                        className="d-none d-md-inline-block">Paso 4: Subir DNIs</span>
                                                                </a>
                                                            </li>
                                                        </ul>

                                                        <div className="tab-content p-3">
                                                            <div className="tab-pane active" id="custom-home"
                                                                 role="tabpanel">
                                                                <p className="mb-0">
                                                                    El primer paso es subir el archivo que contiene los DNIs que están listos para recogerse.
                                                                    El sistema solo admite archivos Excel o CSV. Es importante que se hayan eliminado las páginas
                                                                    que no contienen información a procesar como los Observados. La primera fila debe contener los títulos de cada columna
                                                                </p>
                                                            </div>
                                                            <div className="tab-pane" id="custom-profile"
                                                                 role="tabpanel">
                                                                <p className="mb-0">
                                                                    Una vez subida la información, hay que seleccionar las columnas que contienen la información que se necesita. Por esto es importante que la primera fila contenga los títulos.
                                                                </p>
                                                                <p>
                                                                    Hay que determinar que columna contiene el DNI, la fecha de registro y la fecha en la que fue recogido.
                                                                </p>
                                                            </div>
                                                            <div className="tab-pane" id="custom-messages"
                                                                 role="tabpanel">
                                                                <p className="mb-0">
                                                                    A partir de la información introducida en el paso 2, el sistema mostrará una previsualización de la tabla resultante. Hay que revisar que los datos son los esperados y que está todo en orden.
                                                                </p>
                                                            </div>
                                                            <div className="tab-pane" id="custom-settings"
                                                                 role="tabpanel">
                                                                <p className="mb-0">
                                                                    Finalmente, los datos son enviados al servidor. Una vez que se están procesando los datos, puede cerrar el navegador de forma segura. El sistema procesará la información en segundo plano.
                                                                    Cuando esté todo listo, podrá consultar el resultado en esta página.
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default withTranslation()(AdminDNI);