import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import AdminMenu from "../../components/admin/adminMenu";

export default class Dashboard extends React.Component {
    render() {
        return(
            <div id="layout-wrapper">
                <Navbar />
                <AdminMenu />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="header-title mb-4">El panel de control está en construcción, pero puede gestionar trámites usando el menú superior</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        );
    }
}