
import React from "react";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import image from "../assets/img/error-img.png";

class NotFound extends React.Component {
    render() {
        return(
            <div className="account-pages my-5 pt-5">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="mt-4 text-center">
                                <div>
                                    <img src={image} alt="" className="img-fluid mx-auto d-block" />
                                </div>

                                <h1 className="mt-5 text-uppercase text-white font-weight-bold mb-3">{this.props.t("pages.not_found.title")}</h1>
                                <h5 className="text-white-50">{this.props.t("pages.not_found.subtitle")}</h5>
                                <div className="mt-5">
                                    <Link className="btn btn-success waves-effect waves-light" to={"/"}>
                                        {this.props.t("pages.not_found.back")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotFound);