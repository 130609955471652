import React from "react";
import {withTranslation} from "react-i18next";

class AdminMenu extends React.Component {
    render() {
        const link_dni = "/admin/dni";
        const link_pass ="/admin/pasaporte";
        return(
            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href={link_dni} >
                                        <i className="mdi mdi-smart-card mr-2"/> {this.props.t("components.menu.id")}
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href={link_pass} >
                                        <i className="mdi mdi-passport mr-2"/>{this.props.t("components.menu.passport")}
                                    </a>
                                </li>

            
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AdminMenu);