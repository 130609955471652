import {withTranslation} from "react-i18next";
import React from "react";
import BackendConnectionService from "../../../ports/backend/backendConnectionService";
import ResponseProcessorService from "../../../ports/backend/ResponseProcessorService";
import LocalStorageService from "../../../ports/localStorage/localStorageService";
import Toastify from "../../alerts/Toastify";
import Swal from 'sweetalert2'
import {toast} from "react-toastify";

class PasaporteForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            etiqueta: "MREAC",
            disabled: false
        }
    }

    updateEtiqueta(e) {
        this.setState({
           etiqueta: e.target.value
        });
    }

    validatePass(pass) {
        if(pass === "") {
            return "Debe introducir un número de etiqueta";
        }
        if(!pass.match("^[M][R][E][A][C]+[0-9]+$")) {
            return "La etiqueta parece tener un formato incorrecto. Debe iniciar con MREAC seguido de números solamente.";
        }
        return null;
    }

    triggerAction(action) {
        let etiqueta = this.state.etiqueta;
        let token = LocalStorageService.getUserToken();
        let validate = this.validatePass(etiqueta);
        if(validate == null) {
            if(action === "add") {
                this.addRegistry(etiqueta, token);
            }
            if(action === "mark") {
                this.markRegistry(etiqueta, token);
            }
            if(action === "delete") {
                this.deleteRegistry(etiqueta, token);
            }
            this.setState({
                disabled: true
            });
        } else {
            Toastify.error(toast,validate);
        }
    }

    processError(payload) {
        if(payload.errorCode === 401) {
            LocalStorageService.logout();
            window.location.assign("/admin/inactive");
        } else {
            Toastify.error(toast, payload.errorMsg);
        }
        this.setState({
            disabled: false
        });
    }

    enableButton() {
        this.setState({
            etiqueta: "MREAC",
            disabled: false
        });
    }

    addRegistry(registry, token) {
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de crear un pasaporte con etiqueta " + registry + " ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Crear etiqueta',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.addPasaporte(token, registry)
                    .then((response) => {
                        let payload = ResponseProcessorService.processResponse(response).payload;
                        Toastify.success(toast, "Se ha creado un nuevo documento para el pasaporte con etiqueta "+payload.tag+" con ID: "+payload.id);
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    markRegistry(registry, token) {
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de marcar un pasaporte con etiqueta " + registry + " como recogido ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Recoger pasaporte',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.markPasaporte(token, registry)
                    .then(() => {
                        Toastify.success(toast, "Se ha actualizado el registro correctamente");
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    deleteRegistry(registry, token) {
        Swal.fire({
            title: 'Confirmación',
            text: "Está a punto de eliminar un pasaporte con etiqueta " + registry + " del sistema. Esto no puede revertirse ¿Está seguro?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Eliminar registro',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                BackendConnectionService.deletePasaporte(token, registry)
                    .then(() => {
                        Toastify.success(toast, "Se ha eliminado el registro correctamente");
                        this.enableButton();
                    }).catch((err) => {
                    let payload = ResponseProcessorService.processResponse(err);
                    this.processError(payload);
                })
            }
        })
    }

    render() {
        let disabled = this.state.disabled;
        let text = this.props.text;
        if(disabled) text = "Procesando...";
        return (
            <div className="col-lg-12">
                <div className="form-group mb-4">
                    <label htmlFor="acceso">Etiqueta</label>
                    <input type="text" className="form-control" id="acceso" onChange={(e) => this.updateEtiqueta(e)}
                           placeholder="MREAC99999999999" value={this.state.etiqueta} />
                </div>
                <div className="mt-4">
                    <button
                        className="btn btn-success btn-block waves-effect waves-light"
                        type="button" onClick={() => this.triggerAction(this.props.action)} disabled={disabled}>{text}
                    </button>
                </div>
            </div>
        );
    }
}
export default withTranslation()(PasaporteForm);