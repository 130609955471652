import React from "react";

export default class TramiteResponse extends React.Component {

    render() {
        let response = this.props.response;
        let documento = this.props.documento;
        if(response == null) {
            return(<div />);
        }
         else {
             if(response.status === "query") {
                 return(
                     <div className="row">
                         <div className="col-md-12">
                             <div className="mt-4">
                                 <div className="alert alert-primary" role="alert">
                                     <div className="spinner-border text-primary mr-2 mt-2" role="status">
                                         <span className="sr-only">Cargando...</span>
                                     </div>
                                     Realizando consulta...
                                 </div>
                             </div>
                         </div>
                     </div>
                 );
             } else if (response.status === "response") {
                 let data = response.data;
                 if(!data.error) {
                     if(data.ready) {
                         if(data.recogido === "null") {
                             return(
                                 <div className="row">
                                     <div className="col-md-12">
                                         <div className="mt-4">
                                             <div className="alert alert-success" role="alert">
                                                <p>El {documento} nº {data.documento} se encuentra listo. Puedes recogerlo, sin cita, en el siguiente horario: </p>
                                                <ul>
                                                    <li>Lunes a Viernes de 09h00 a 13h00 y de 14h00 a 15h30</li>
                                                </ul>

                                                <p>Recuerda traer el resguardo de tu trámite. Si necesitas que otra persona recoja tu {documento}, no olvides que se debe presentar una autorización simple (en el mismo resguardo).</p> 

                                                <p>Envío por correo postal: Si solicitaste esta opción, deberás esperar unos días adicionales para recibir el {documento}. </p>
                                                <p>Si tienes alguna duda, escríbenos a consulado@consulperubarcelona.com</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             );
                         } else {
                             let fecha = data.recogido;
                             return(
                                 <div className="row">
                                     <div className="col-md-12">
                                         <div className="mt-4">
                                             <div className="alert alert-warning" role="alert">
                                                 El {documento} nº {data.documento} ha sido entregado en la fecha {fecha}
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             );
                         }
                     } else {
                         let documentoText = "DNI nº "+data.documento;
                         if(documento === "Pasaporte") {
                             documentoText = "Pasaporte con etiqueta nº " + data.documento;
                         }
                         return(
                             <div className="row">
                                 <div className="col-md-12">
                                     <div className="mt-4">
                                         <div className="alert alert-danger" role="alert">
                                             <p>El {documentoText} aún no está listo para ser entregado. </p>
                                             <p>Verifica los plazos de tramitación. Si crees que puede haber un error, escríbenos a consulado@consulperubarcelona.com  </p>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         );
                     }
                 } else {
                     return(
                         <div className="row">
                             <div className="col-md-12">
                                 <div className="mt-4">
                                     <div className="alert alert-warning" role="alert">
                                         Hubo un error procesando la solicitud de información. Vuelva a intentarlo más tarde.
                                     </div>
                                 </div>
                             </div>
                         </div>
                     );
                 }
             }
        }
    }
}