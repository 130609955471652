import React from "react";
import {Link} from "react-router-dom";
import logo from "../assets/img/logo-blanco-plano.png";
import LangSwitcher from "./langSwitcher";
import face from "../assets/img/avatar-1.png";
import LocalStorageService from "../ports/localStorage/localStorageService";

export default class Navbar extends React.Component {
    logout() {
        LocalStorageService.logout();
        window.location.reload();
    }
    renderAccount() {
        const no_link = "#";
        if(LocalStorageService.getUserToken() == null) {
            return(<div />);
        }
        let name = LocalStorageService.getUserName();
        return(
            <div className="dropdown d-inline-block">
                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="rounded-circle header-profile-user" src={face} alt="Header Avatar" />
                    <span className="d-none d-sm-inline-block ml-1">{name}</span>
                    <i className="mdi mdi-chevron-down d-none d-sm-inline-block" />
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item" href={"/admin"}><i className="mdi mdi-view-dashboard font-size-16 align-middle mr-1"/> Administración</a>
                    <a className="dropdown-item" href={"/admin/config"}><i className="mdi mdi-account-settings font-size-16 align-middle mr-1"/> Configuración</a>
                    <div className="dropdown-divider"/>
                    <a className="dropdown-item" href={no_link} onClick={() => this.logout()}><i className="mdi mdi-logout font-size-16 align-middle mr-1"/> Cerrar sesión</a>
                </div>
            </div>
        );
    }
    render() {
        return(
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to={"/"} className="logo">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="60" />
                                    </span>
                                <span className="logo-lg">
                                        <img src={logo} alt="" height="60" />
                                    </span>
                            </Link>
                        </div>
                        <button type="button"
                                className="btn btn-sm mr-2 font-size-16 d-lg-none header-item waves-effect waves-light collapsed"
                                data-toggle="collapse" data-target="#topnav-menu-content" aria-expanded="false">
                            <i className="fa fa-fw fa-bars"/>
                        </button>
                    </div>

                    <div className="d-flex">
                        <LangSwitcher />
                    </div>
                    {this.renderAccount()}
                </div>
            </header>
        );
    }
}