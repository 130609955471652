
export default class Toastify {
    static success(toast, msg) {
        toast.success(
            msg,
            {
                position: "top-right",
                autoClose: 3000,
            }
        );
    }
    static info(toast, msg) {
        toast.info(
            msg,
            {
                position: "top-right",
                autoClose: 3000,
            }
        );
    }
    static warning(toast, msg) {
        toast.warn(
            msg,
            {
                position: "top-right",
                autoClose: 3000,
            }
        );

    }
    static error(toast, msg) {
        toast.error(
            msg,
            {
                position: "top-right",
                autoClose: 3000,
            }
        );
    }
}