import {withTranslation} from "react-i18next";
import React from "react";
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import BackendConnectionService from "../../../ports/backend/backendConnectionService";
import LocalStorageService from "../../../ports/localStorage/localStorageService";
import { toast } from "react-toastify";
import Toastify from "../../alerts/Toastify";

class SubirDNI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            file: null
        }
    }

    enableButton() {
        this.setState({
            disabled: false
        });
    }

    disableButton() {
        this.setState({
            disabled: true
        });
    }

    changeFile(e) {
        this.setState({
            file: e.target.files[0]
        });
    }

    subirArchivo() {
        Toastify.info(toast, "Leyendo archivo, espere...");
        this.disableButton();
        let file = this.state.file;
        let reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {raw: true});
            let cleanData = [];
            let keys = null;
            wb.SheetNames.forEach(name => {
                const ws = wb.Sheets[name];
                const data = XLSX.utils.sheet_to_json(ws, {});
                if(keys == null) keys = Object.keys(data[0]);
                data.forEach(row => {
                    cleanData.push(row);
                })
            });
            if(keys != null) {
                this.setState({
                    columnas: keys,
                    data: cleanData
                });
            }
        };
        reader.readAsArrayBuffer(file);
        this.enableButton();
    }

    generateOpts() {
        let cols = this.state.columnas;
        let elems = [];
        let i = 0;
        elems.push(<option key="-1" value="-1">Seleccione...</option>);
        cols.forEach(row => {
            elems.push(<option key={i} value={i}>{row}</option>);
            i = i + 1;
        });
        return elems;
    }

    submitMapping() {
        this.setState({
            mapped: true
        });
    }

    mapDNI(e) {
        this.setState({
            dniMap: e.target.value
        });
    }

    mapRegistro(e) {
        this.setState({
            registroMap: e.target.value
        });
    }

    mapNombre(e) {
        this.setState({
            nombreMap: e.target.value
        });
    }

    prepareData() {
        let {data, dniMap, registroMap,nombreMap, recepcionMap, columnas} = this.state;
        let cleanData = [];
        data.forEach(x => {
           cleanData.push({
               dni: x[columnas[dniMap]],
               registral: x[columnas[registroMap]],
               nombres: x[columnas[nombreMap]],
               recepcion: x[columnas[recepcionMap]]
           });
        });
        return cleanData;
    }

    submitDNI(data) {
        this.setState({
            disabled: true
        });
        let token = LocalStorageService.getUserToken();
        BackendConnectionService.uploadDNIs(token, data).then((e) => {
            this.setState({
                disabled: false,
                file: null,
                mapped: null,
                data: null,
                dniMap: null,
                registroMap: null,
                nombreMap: null,
                columnas: null,
            });
            window.location.reload();
        });
    }

    render() {
        let disabled = this.state.disabled;
        let file = this.state.file;
        let text = "Subir DNIs"
        if(disabled) text = "Procesando...";
        if(file == null) {
            text = "Seleccione un archivo para continuar";
            file = "Click para seleccionar";
            disabled = true;
        }
        else file = file.name;
        if(this.state.data != null && this.state.mapped == null) {
            return (
                <div className="col-lg-12">
                    <p>Para cada opción, seleccione la columna correspondiente.</p>
                    <div className="col-md-12 mb-3">
                        <label>DNI</label>
                        <select className="custom-select" onChange={(e) => this.mapDNI(e)} required="">
                            {this.generateOpts()}
                        </select>
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Ficha registral</label>
                        <select className="custom-select" onChange={(e) => this.mapRegistro(e)} required="">
                            {this.generateOpts()}
                        </select>
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Nombres y apellidos</label>
                        <select className="custom-select" onChange={(e) => this.mapNombre(e)} required="">
                            {this.generateOpts()}
                        </select>
                    </div>
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.submitMapping()}>Continuar
                        </button>
                    </div>
                </div>
            );
        } else if(this.state.mapped != null) {
            let {dniMap, registroMap, nombreMap} = this.state;
            let keys = this.state.columnas;
            let cols = [{
                name: "DNI",
                selector: row => {
                    let selector1 = keys[dniMap];
                    return row[selector1];
                }
            },{
                name: "F. Registral",
                selector: row => {
                    let selector1 = keys[registroMap];
                    return row[selector1];
                }
            },{
                name: "Nombres",
                selector: row => {
                    let selector1 = keys[nombreMap];
                    return row[selector1];
                }
            }];
            let dataToBePushed = this.prepareData();
            return (
                <div className="col-lg-12">
                    <p>Previsualización de datos. Verifique que la información mostrada corresponde con lo que debe subirse al sistema.</p>
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={cols}
                        data={this.state.data}
                    />
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.submitDNI(dataToBePushed)} disabled={disabled}>{text}
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-lg-12">
                    <div className="form-group mb-4">
                        <label>Archivo</label>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" accept=".csv,.xlsx,.xls" id="validationCustomFile" onChange={(e) => this.changeFile(e)} required="" />
                            <label className="custom-file-label" htmlFor="validationCustomFile">{file}</label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            className="btn btn-success btn-block waves-effect waves-light"
                            type="button" onClick={() => this.subirArchivo()} disabled={disabled}>{text}
                        </button>
                    </div>

                </div>
            );
        }

    }
}
export default withTranslation()(SubirDNI);