

export default class LocalStorageService {
    static logout() {
        localStorage.removeItem("user_token");
    }
    static getUserToken() {
        return localStorage.getItem("user_token");
    }
    static getUserName() {
        return localStorage.getItem("user_name");
    }
    static isFirstSetup() {
        return localStorage.getItem("first_setup") == null
    }
    static getUserLanguage() {
        let lang = localStorage.getItem("i18nextLng");
        if(lang == null) {
            lang = "es-ES";
        }
        return lang;
    }
    static setUserInformation(data) {
        localStorage.setItem("user_token",data);
        localStorage.setItem("user_name", "Administrador");
    }
    static doFirstSetup() {
        localStorage.setItem("first_setup", "true");
    }
    static changeTramite(tramite) {
        localStorage.setItem("tramite",tramite);
    }
    static getTramite() {
        return localStorage.getItem("tramite");
    }
}