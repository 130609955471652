import React from "react";
import {withTranslation} from "react-i18next";

class Footer extends React.Component {
    render() {
        return(
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            {this.props.t("components.footer.year")} © Consulado General del Perú en Barcelona.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-right d-none d-sm-block">
                                {this.props.t("components.footer.developed")} <i className="mdi mdi-heart text-danger"/> {this.props.t("components.footer.by")} APlus Digital Solutions OÜ
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);